import {
  getDatabase,
  onValue,
  push,
  ref,
  set,
  update,
} from "firebase/database";
import React, { useEffect, useState } from "react";
import { Linking, View } from "react-native";
import {
  Button,
  Paragraph,
  Modal,
  Portal,
  Divider,
  Headline,
  Subheading,
  TextInput,
} from "react-native-paper";

import { UserContext } from "./AuthenticatedUserProvider";
import tw from "../../lib/tailwind";
import colors from "../../styles/colors";
import { Formik } from "formik";

export const ExistingInvestorCheck = () => {
  const [allFunds, setAllFunds] = useState<any>({});

  const { user } = UserContext();

  const db = getDatabase();

  useEffect(() => {
    const reference = ref(db, `funds`);
    onValue(reference, (snapshot) => {
      const snapshotValues = snapshot.val();
      if (snapshotValues != allFunds) {
        setAllFunds(snapshotValues);
      }
    });
  }, []);

  function skipInvestorSetup() {
    if (!user) return;
    const db = getDatabase();

    const usersRef = ref(db, "users/" + user.uid);
    update(usersRef, {
      skipInvestorSetup: true,
    });
  }

  function saveSetupComplete(values: any) {
    if (!user) return;
    const db = getDatabase();

    const matchingFund = allFunds.find(
      (fund: any) => !!fund?.name && fund.name === values.accessCode
    );

    const newFundIndex = user.funds?.length || 0;
    const usersFundsRef = ref(db, "users_funds/" + user.uid);
    update(usersFundsRef, {
      [newFundIndex]: {
        id: matchingFund.id,
        fund: matchingFund.id,
        investedAmount: 1,
      },
    });
    skipInvestorSetup();
  }
  const containerStyle = { backgroundColor: "white", padding: 20, margin: 20 };

  function emailUs() {
    Linking.openURL("mailto:support@thebitinvesting.com");
  }

  return (
    <Portal>
      <Modal
        onDismiss={skipInvestorSetup}
        visible={user?.skipInvestorSetup == false}
        contentContainerStyle={containerStyle}
      >
        <Headline style={tw`font-semibold mb-6`}>Welcome to The Bit!</Headline>

        <Subheading style={tw`font-medium`}>Already an investor?</Subheading>
        <Paragraph>
          Please enter the access code provided by your fund, or{" "}
          <Paragraph onPress={emailUs} style={tw`text-blue-500 underline mb-2`}>
            email us
          </Paragraph>{" "}
          to get assistance.
        </Paragraph>

        <Formik
          initialValues={{
            accessCode: "",
          }}
          onSubmit={saveSetupComplete}
        >
          {({ handleChange, values, handleBlur, submitForm }) => (
            <View style={tw`flex-row flex-wrap items-center justify-between`}>
              <>
                <TextInput
                  style={tw`w-full m-1`}
                  value={values.accessCode}
                  onChangeText={handleChange("accessCode")}
                  onBlur={handleBlur("accessCode")}
                  mode="outlined"
                  label="Access Code"
                />
                <Button
                  mode="outlined"
                  color={colors.green[800]}
                  onPress={submitForm}
                  style={tw`mt-2 mx-auto`}
                >
                  Submit
                </Button>
              </>
            </View>
          )}
        </Formik>

        <Divider style={tw`w-full my-6`} />

        <Subheading style={tw`font-medium`}>Not an investor?</Subheading>
        <Paragraph>
          No problem! You'll still be able to see sample data.
        </Paragraph>

        <Button
          mode="outlined"
          onPress={skipInvestorSetup}
          style={tw`mt-2 mx-auto`}
        >
          Skip
        </Button>
      </Modal>
    </Portal>
  );
};
