import React from "react";
import { Image, Linking, Platform, View } from "react-native";
import { Button, Title } from "react-native-paper";
import { useDeviceContext } from "twrnc";

import { StackHeaderProps } from "@react-navigation/stack";

import tw from "../../../lib/tailwind";
import { PageWrapperLanding } from "../../components/PageWrappers";

const WelcomeScreen = ({ navigation }: StackHeaderProps) => {
  useDeviceContext(tw);

  const loginClicked = () => {
    if (Platform.OS === "ios") {
      Linking.openURL("https://thebitinvesting.com/Login");
    } else {
      navigation.navigate("Login");
    }
  };

  const signupClicked = () => {
    if (Platform.OS === "ios") {
      Linking.openURL("https://thebitinvesting.com/Signup");
    } else {
      navigation.navigate("Signup");
    }
  };

  return (
    <PageWrapperLanding>
      <>
        <Image
          style={tw`w-24 h-22 mb-10`}
          source={require("../../../assets/icons/icon-light.png")}
        />
        <Title
          style={{
            ...tw`text-5xl text-white mb-3`,
            fontFamily: "Lora",
          }}
        >
          Welcome to
        </Title>
        <Title
          style={{
            ...tw`text-5xl text-white`,
            fontFamily: "Lora",
          }}
        >
          The Bit
        </Title>
        <Title style={tw`text-xl text-white my-12`}>
          Horse investing for the modern age.
        </Title>

        <View style={tw`w-full`}>
          <Button
            mode="contained"
            style={tw`w-full`}
            contentStyle={tw`py-2`}
            onPress={signupClicked}
          >
            Get Started
          </Button>
          <Button
            color="white"
            mode="outlined"
            style={tw`w-full my-2 border-green-400`}
            contentStyle={tw`py-2`}
            onPress={loginClicked}
          >
            Login
          </Button>
        </View>
      </>
    </PageWrapperLanding>
  );
};

export default WelcomeScreen;
