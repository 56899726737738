import { useFonts } from "expo-font";
import React from "react";
import {
  ActivityIndicator,
  DefaultTheme,
  Provider as PaperProvider,
} from "react-native-paper";

import colors from "../../styles/colors";
import { ProviderProps } from "./types";

export const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: colors.green[400],
    secondaryContainer: colors.green[200],
    elevation: {
      level0: colors.green[50],
      level1: colors.green[50],
      level2: colors.green[50],
      level3: colors.green[50],
      level4: colors.green[50],
      level5: colors.green[50],
    },
  },
};

export const BitThemeProvider: React.FC<ProviderProps> = ({ children }) => {
  // TODO avoid require, can they be imports?
  const [fontsLoaded] = useFonts({
    Lora: require("../../assets/fonts/Lora/Lora-VariableFont_wght.ttf"),
  });

  if (!fontsLoaded) {
    return <ActivityIndicator />;
  }
  return <PaperProvider theme={theme}>{children}</PaperProvider>;
};
