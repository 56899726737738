import { getDatabase, ref, update } from "firebase/database";
import React, { useEffect, useState } from "react";
import { Dimensions, View } from "react-native";
import {
  Button,
  ActivityIndicator,
  Card,
  Paragraph,
  Title,
  Caption,
} from "react-native-paper";

import { UserContext } from "./AuthenticatedUserProvider";
import tw from "../../lib/tailwind";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { NavigationContainerRefWithCurrent } from "@react-navigation/native";
import {
  manageTabTitle,
  portfolioTabTitle,
} from "../navigation/stacks/UserStack";

type UserPageHeaderProps = {
  navigationRef: NavigationContainerRefWithCurrent<any>;
};

export const AdminProfileProvider = ({
  navigationRef,
}: UserPageHeaderProps) => {
  const { user } = UserContext();
  const [stepNumber, setStepNumber] = useState(0);

  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  const insets = useSafeAreaInsets();
  const borderWidth = windowWidth;
  const borderHeight = windowHeight;
  const navBarHeight = 80;
  const topOfNavBar = insets.bottom + navBarHeight;
  const navButtonWidth = windowWidth / 4;

  const styles = {
    wrapper: {
      height: "100%",
      width: "100%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      zIndex: 30,
    },
  };

  const defaultBackgroundStyle = {
    position: "absolute",
    borderColor: "#171717cc",
    bottom: "0%",
    left: "0%",
    width: borderWidth,
    height: borderHeight,
    borderWidth: 0,
    borderTopWidth: borderHeight,
    borderRightWidth: borderWidth,
  };

  const customerViewPageTitle = "Customer View";
  const stepObjects = [
    {
      title: user?.adminName ? `Welcome, ${user?.adminName}` : `Welcome!`,
      body: "Let's give you a tour of your account. This will only take a minute.",
    },
    {
      title: "Manager Tab",
      body: "This is where you will find manager-specific functionality. Your investors do not see this tab.",
      backgroundStyle: {
        width: navButtonWidth + borderWidth,
        height: topOfNavBar + borderHeight,
      },
    },
    {
      title: "Manager Tab",
      body: "From here, you can send out updates to your investors.",
      bottomClass: topOfNavBar,
      backgroundStyle: {
        borderColor: "#17171733",
        width: navButtonWidth + borderWidth,
        height: topOfNavBar + borderHeight,
      },
    },
    {
      title: customerViewPageTitle,
      body: "The other tabs will be the same for both you and your investors.",
      backgroundStyle: {
        width: navButtonWidth * 4 + borderWidth,
        height: topOfNavBar + borderHeight,
        borderLeftWidth: navButtonWidth,
      },
      selectedTab: portfolioTabTitle,
    },
    {
      title: customerViewPageTitle,
      body:
        `However, within the Certificate of Ownership, ` +
        `you will see '0%', and 'Example LLC'. Your investors will see their ` +
        `invested amount and investment vehicle.`,
      bottomClass: topOfNavBar,
      backgroundStyle: {
        borderColor: "#17171733",
        width: navButtonWidth * 2 + borderWidth,
        height: topOfNavBar + borderHeight,
        borderLeftWidth: navButtonWidth,
      },
      selectedTab: portfolioTabTitle,
    },
    {
      title: "All set!",
      body: "Don't hesitate to reach out with any questions.",
    },
  ];

  const thisStepObj = stepObjects[stepNumber];

  useEffect(() => {
    if (!navigationRef.isReady()) return;
    if (!user?.isAdmin) return;
    navigationRef.navigate(thisStepObj.selectedTab || manageTabTitle);
  }, [thisStepObj, navigationRef.isReady()]);

  function nextStep() {
    if (stepNumber < stepObjects.length - 1) {
      setStepNumber(stepNumber + 1);
    } else {
      saveSetupComplete();
    }
  }

  function previousStep() {
    if (stepNumber > 0) {
      setStepNumber(stepNumber - 1);
    }
  }

  function saveSetupComplete() {
    if (!user) return;
    const db = getDatabase();

    const usersFundsRef = ref(db, "users_funds/" + user.uid);
    update(usersFundsRef, {
      0: {
        id: user.adminFundId,
        fund: user.adminFundId,
        investedAmount: 1,
        investedAsGroupName: "Example LLC",
      },
    });

    const usersRef = ref(db, "users/" + user.uid);
    update(usersRef, {
      adminSetupComplete: true,
      isAdmin: true,
    });
  }

  if (user?.isAdmin && !user.adminSetupComplete) {
    if (!thisStepObj) return <ActivityIndicator size="large" />;
    return (
      <View style={styles.wrapper}>
        <View
          style={{
            ...defaultBackgroundStyle,
            ...thisStepObj.backgroundStyle,
          }}
        ></View>
        <Card
          style={{
            ...tw`bg-white m-3 z-50 absolute`,
            bottom: thisStepObj.bottomClass || windowHeight / 2,
          }}
        >
          <Card.Content>
            <Title>
              {thisStepObj.title}{" "}
              <Caption>
                ({stepNumber + 1} of {stepObjects.length})
              </Caption>
            </Title>
            {thisStepObj.body && <Paragraph>{thisStepObj.body}</Paragraph>}
          </Card.Content>
          <Card.Actions>
            {stepNumber > 0 && (
              <Button mode="text" onPress={previousStep}>
                Back
              </Button>
            )}
            <Button mode="text" onPress={nextStep}>
              Continue
            </Button>
          </Card.Actions>
        </Card>
      </View>
    );
  }

  return <></>;
};
