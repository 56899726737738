import React from "react";
import { Linking } from "react-native";
import { Button, ButtonProps } from "react-native-paper";

import tw from "../../lib/tailwind";

export const EmailUsButton = (props: Partial<ButtonProps>) => {
  function emailUs() {
    Linking.openURL("mailto:support@thebitinvesting.com");
  }
  return (
    <Button
      mode="outlined"
      onPress={emailUs}
      style={tw`w-full my-4`}
      contentStyle={tw`py-2`}
      {...props}
    >
      Email us
    </Button>
  );
};
