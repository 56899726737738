import React from "react";
import { ImageBackground, SafeAreaView, ScrollView, View } from "react-native";
import { useDeviceContext } from "twrnc";

import tw from "../../lib/tailwind";

const baseContainerStyles =
  "max-h-screen max-w-screen w-full h-full flex-1 items-center justify-center";

const baseWrapperStyles =
  "flex items-start justify-center max-w-md md:max-h-180 w-full h-full md:rounded md:border-2 md:border-gray-300";

type UserPageWrapperProps = {
  children: JSX.Element;
};

export const UserPageWrapper: React.FC<UserPageWrapperProps> = ({
  children,
}) => {
  useDeviceContext(tw);

  return (
    <ScrollView
      style={tw`p-4 pb-6 h-full`}
      contentContainerStyle={tw`items-center items-center h-full`}
    >
      {children}
    </ScrollView>
  );
};

type VisitorPageWrapperProps = {
  children: JSX.Element;
};

export const VisitorPageWrapper: React.FC<VisitorPageWrapperProps> = ({
  children,
}) => {
  useDeviceContext(tw);

  return (
    <SafeAreaView style={tw`${baseContainerStyles} bg-white`}>
      <View style={tw`${baseWrapperStyles} bg-white`}>{children}</View>
    </SafeAreaView>
  );
};

export const PageWrapperLanding: React.FC<VisitorPageWrapperProps> = ({
  children,
}) => {
  useDeviceContext(tw);

  const backgroundImage = {
    uri: "https://res.cloudinary.com/the-bit/image/upload/q_auto:low/v1664284650/dark-horse-eye-closeup_zazbir.png",
  };

  return (
    <>
      <ImageBackground
        style={[tw`absolute w-full h-full opacity-80 bg-gray-600`]}
        source={backgroundImage}
      />
      <SafeAreaView style={tw`${baseContainerStyles}`}>
        <View style={tw`${baseWrapperStyles} p-8`}>{children}</View>
      </SafeAreaView>
    </>
  );
};
