import React from "react";

import { NavigationContainer, Theme } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import { theme } from "../../providers/ThemeProvider";
import LoginScreen from "../../screens/visitor-stack/LoginScreen";
import SignupScreen from "../../screens/visitor-stack/SignupScreen";
import WelcomeScreen from "../../screens/visitor-stack/WelcomeScreen";
import { NavLinking } from "./UserStack";

const Stack = createStackNavigator();

export default function Visitor() {
  return (
    <NavigationContainer linking={NavLinking} theme={theme as Theme}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName="Welcome"
      >
        <Stack.Screen name="Welcome" component={WelcomeScreen} />
        <Stack.Screen name="Login" component={LoginScreen} />
        <Stack.Screen name="Signup" component={SignupScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
