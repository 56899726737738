import React, { useEffect, useState } from "react";
import { Appbar } from "react-native-paper";

import { NavigationContainerRefWithCurrent } from "@react-navigation/native";

import tw from "../../lib/tailwind";
import {
  LoggedInProfilePageName,
  discoverTabTitle,
} from "../navigation/stacks/UserStack";
import { UserContext } from "../providers/AuthenticatedUserProvider";

type UserPageHeaderProps = {
  navigationRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>;
};

export default function UserPageHeader({ navigationRef }: UserPageHeaderProps) {
  const [pageTitle, setPageTitle] = useState("The Bit");
  const { user } = UserContext();

  useEffect(() => {
    navigationRef.addListener("state", () => {
      let pageTitle = navigationRef?.getCurrentRoute()?.name || "The Bit";

      pageTitle = switchTitles(pageTitle);

      setPageTitle(pageTitle);
    });
  });

  function switchTitles(title: string) {
    if (title === discoverTabTitle) {
      return "Welcome";
    }
    return title;
  }

  return (
    <Appbar.Header elevated={true} style={tw`bg-white`}>
      <Appbar.Content
        title={pageTitle}
        titleStyle={{
          fontFamily: "Lora",
        }}
        style={{ alignItems: "flex-start" }}
      />

      <Appbar.Action
        icon="account"
        onPress={() => {
          // TODO TS doesnt like this, but it works
          navigationRef.navigate(LoggedInProfilePageName);
        }}
      />
    </Appbar.Header>
  );
}
