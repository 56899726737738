import React from "react";
import { Text, View } from "react-native";

import tw from "../../lib/tailwind";

type ErrorMessageProps = {
  error: string | undefined | boolean;
};

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  if (!error) return <></>;
  const defaultErrorMessage =
    "We had an error! Please contact us if the problem persists.";

  let errorMessage = typeof error === "string" ? error : defaultErrorMessage;

  return (
    <View style={tw`mb-2`}>
      <Text style={tw`text-yellow-600`}>{errorMessage}</Text>
    </View>
  );
};

export default ErrorMessage;
