import React, { useState } from "react";
import { Keyboard, SafeAreaView, ScrollView, View } from "react-native";
import {
  List,
  Title,
  TextInput,
  Button,
  Divider,
  Card,
  Avatar,
  Paragraph,
  Portal,
  Modal,
  Snackbar,
  Headline,
} from "react-native-paper";
import { getDatabase, ref, update } from "firebase/database";

import tw from "../../../lib/tailwind";
import { UserContext } from "../../providers/AuthenticatedUserProvider";

// TODO this whole file needs to be refactored/cleaned up

export default function Update() {
  const { user } = UserContext();

  const [stepNum, setStepNum] = useState(0);

  const [horseUpdates, setHorseUpdates] = React.useState({});

  const handleChange = (horseName: string, message: string) => {
    let updatedValue = {};
    updatedValue[horseName] = message;

    setHorseUpdates((horseUpdate) => ({
      ...horseUpdate,
      ...updatedValue,
    }));
  };

  let numberOfHorses = 0;
  if (user && user.funds?.length && user?.funds[0]?.horses?.length) {
    numberOfHorses = user?.funds[0]?.horses?.length;
  }

  function nextStep() {
    if (stepNum === numberOfHorses) {
      return showReviewModal();
    }
    setStepNum(stepNum + 1);
  }

  const [visible, setVisible] = React.useState(false);

  const showReviewModal = () => {
    Keyboard.dismiss();
    setVisible(true);
  };
  const hideModal = () => setVisible(false);

  const submitUpdate = () => {
    const db = getDatabase();
    const reference = ref(db, "updates/" + Date.now());
    update(reference, { ...horseUpdates, author: user?.email });
    setStepNum(0);
    setHorseUpdates({});
    onToggleSnackBar();
    hideModal();
  };

  const [visibleSnack, setVisibleSnack] = React.useState(false);

  const onToggleSnackBar = () => setVisibleSnack(!visibleSnack);

  const onDismissSnackBar = () => setVisibleSnack(false);

  const accordionContainerStyles = "rounded border-2 border-gray-100 my-1";

  return (
    <View style={tw`h-full`}>
      <Snackbar visible={visibleSnack} onDismiss={onDismissSnackBar}>
        Submitted! We'll send your update to your investors.
      </Snackbar>
      <ScrollView automaticallyAdjustKeyboardInsets style={tw`p-4 py-0`}>
        <Portal>
          <Modal
            visible={visible}
            onDismiss={hideModal}
            contentContainerStyle={tw`m-8`}
          >
            <SafeAreaView>
              <Card style={tw`max-h-full pt-4`}>
                <ScrollView>
                  <Card.Content>
                    <Headline>Message Preview</Headline>
                    <Divider />
                    <Title>From:</Title>
                    <Paragraph>Corbin Blumberg via The Bit</Paragraph>
                    <Title>Subject:</Title>
                    <Paragraph>
                      Northstar Investor Update - {new Date().toDateString()}
                    </Paragraph>
                    <Divider />
                    <Title>Northstar Racing Investor Update</Title>
                    <View>
                      <Title>General</Title>
                      <Paragraph>{horseUpdates["General"]}</Paragraph>
                    </View>
                    {Object.keys(horseUpdates).map((key) => {
                      if (key === "General") return;
                      return (
                        <View key={key}>
                          <Title>{key}</Title>
                          <Paragraph>{horseUpdates[key]}</Paragraph>
                        </View>
                      );
                    })}
                  </Card.Content>
                </ScrollView>
                <Card.Actions>
                  <Button onPress={hideModal}>Edit</Button>
                  <Button onPress={submitUpdate}>Send</Button>
                </Card.Actions>
              </Card>
            </SafeAreaView>
          </Modal>
        </Portal>
        <View>
          <Title style={tw`mt-4`}>Update investors</Title>

          <View
            style={{
              ...tw`${accordionContainerStyles}`,
            }}
          >
            <List.Accordion
              title="General"
              expanded={stepNum === 0}
              onPress={() => setStepNum(0)}
            >
              <Card style={tw`pl-0 bg-white`}>
                <Card.Content>
                  <TextInput
                    value={horseUpdates.General}
                    mode="outlined"
                    label="General update for the fund"
                    multiline={true}
                    onChangeText={(text: string) => {
                      handleChange("General", text);
                    }}
                  />
                </Card.Content>

                <Card.Actions>
                  <Button mode="contained" onPress={nextStep}>
                    Next
                  </Button>
                </Card.Actions>
              </Card>
            </List.Accordion>
          </View>

          {user?.funds?.length &&
            user.funds[0].horses?.map((horse, index) => {
              const thumbnailUrl = horse.imageUrl.replace(
                "upload/v",
                "upload/c_thumb,w_200/v"
              );

              return (
                <View
                  style={{
                    ...tw`${accordionContainerStyles}`,
                  }}
                  key={horse.name}
                >
                  <List.Accordion
                    title={horse.name}
                    expanded={stepNum === index + 1}
                    onPress={() => setStepNum(index + 1)}
                    left={(props) => (
                      <Avatar.Image
                        {...props}
                        size={48}
                        source={{ uri: thumbnailUrl }}
                      />
                    )}
                  >
                    <Card style={tw`pl-0 bg-white`}>
                      <Card.Content>
                        <TextInput
                          value={horseUpdates[horse.name]}
                          mode="outlined"
                          label={`Update for ${horse.name}`}
                          onChangeText={(text: string) => {
                            handleChange(horse.name, text);
                          }}
                          multiline={true}
                        />
                      </Card.Content>

                      <Card.Actions>
                        <Button mode="contained" onPress={nextStep}>
                          Next
                        </Button>
                      </Card.Actions>
                    </Card>
                  </List.Accordion>
                </View>
              );
            })}

          <Button
            mode="contained"
            onPress={showReviewModal}
            style={tw`mt-2 mb-4`}
          >
            Review
          </Button>
        </View>
      </ScrollView>
    </View>
  );
}
