import { reload, updateProfile } from "firebase/auth";
import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Button,
  Caption,
  Card,
  Subheading,
  Text,
} from "react-native-paper";

import { auth } from "../../../lib/firebase";
import tw from "../../../lib/tailwind";
import { OwnershipCertificateModal } from "../OwnershipCertificateModal";
import { SetNameModal } from "../SetNameModal";
import { HorseDetails } from "./HorseDetails";
import { FundType } from "../../providers/AuthenticatedUserProvider";
import { FundUpdatesModal } from "../FundUpdatesModal";
import { View } from "react-native";
import { getDatabase, onValue, ref } from "firebase/database";

type InvestmentCardProps = {
  fund: FundType;
};

export const InvestmentCard = ({ fund }: InvestmentCardProps) => {
  const { id, name, horses, isSampleData, investedAsGroupName } = fund;

  if (!id || !horses?.length || !name) {
    return <ActivityIndicator size="large" />;
  }

  const [isUpdatesModalVisible, setIsUpdatesModalVisible] = useState(false);
  const hideUpdatesModal = () => setIsUpdatesModalVisible(false);
  const showUpdatesModal = () => setIsUpdatesModalVisible(true);

  const db = getDatabase();

  const [allUpdates, setAllUpdates] = useState<any>({});
  const [lastUpdateTime, setLastUpdateTime] = useState<any>();

  useEffect(() => {
    const reference = ref(db, `updates/${id}`);
    onValue(reference, (snapshot) => {
      const snapshotValues = snapshot.val();
      if (snapshotValues != allUpdates) {
        setAllUpdates(snapshotValues);
        const lastUpdateValue = parseInt(Object.keys(snapshotValues)[0]);
        const dateObj = new Date(lastUpdateValue);
        setLastUpdateTime(timeAgo(dateObj));
      }
    });
  }, []);

  return (
    <>
      <Card style={tw`bg-white m-2 py-4 mx-0 max-w-sm`} elevation={1}>
        <Card.Content
          style={tw`flex-row my-1 items-start justify-between flex-wrap`}
        >
          <Card.Title
            style={{ ...tw`min-h-0 mb-2` }}
            titleStyle={{ fontFamily: "Lora" }}
            titleVariant="headlineMedium"
            title={name}
          />
          <View>
            <Button
              icon="bell-badge"
              mode="contained"
              style={tw`bg-green-700`}
              onPress={showUpdatesModal}
            >
              Updates
            </Button>
            <Caption style={{ fontSize: "10px", lineHeight: "12px", textAlign: "right" }}>
              Last Updated: {lastUpdateTime}
            </Caption>
          </View>
        </Card.Content>
        <HorseDetails horses={horses} />
      </Card>
      {allUpdates && (
        <FundUpdatesModal
          isUpdatesModalVisible={isUpdatesModalVisible}
          hideUpdatesModal={hideUpdatesModal}
          allUpdates={allUpdates}
        />
      )}
    </>
  );
};

function timeAgo(date: Date) {
  const now = new Date();
  const differenceInMs = now.getTime() - new Date(date).getTime(); // Difference in milliseconds
  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24)); // Convert to days

  if (differenceInDays === 0) {
    return "Today";
  } else if (differenceInDays === 1) {
    return "Yesterday";
  } else {
    return `${differenceInDays} days ago`;
  }
}
