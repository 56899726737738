import React, { ReactElement } from "react";
import { getDatabase, ref, update } from "firebase/database";

import { InvestmentCard } from "../../components/InvestmentCard/InvestmentCard";
import { UserPageWrapper } from "../../components/PageWrappers";
import {
  FundType,
  UserContext,
} from "../../providers/AuthenticatedUserProvider";
import { Button } from "react-native-paper";
import { sampleFundInfo } from "../../utils";

export type HorseProps = {
  name: string;
  id: number;
  imageUrl: string;
  age: number;
  trainer: string;
  location: string;
  description?: string;
};

const Portfolio = (): ReactElement => {
  const { user } = UserContext();

  function restartInvestorSetup() {
    if (!user) return;
    const db = getDatabase();

    const usersRef = ref(db, "users/" + user.uid);
    update(usersRef, {
      skipInvestorSetup: false,
    });
  }

  const userAlreadyHasFunds =
    !!user?.funds?.length && user.funds[0].id !== sampleFundInfo.id;

  return (
    <UserPageWrapper>
      <>
        {!userAlreadyHasFunds ? (
          <Button onPress={restartInvestorSetup}>
            Already an investor? Click here.
          </Button>
        ) : (
          <Button onPress={restartInvestorSetup}>
            To add another investment, click here.
          </Button>
        )}
        {user?.funds?.map((fund: FundType) => (
          <React.Fragment key={fund.id}>
            <InvestmentCard fund={fund} />
          </React.Fragment>
        ))}
      </>
    </UserPageWrapper>
  );
};

export default Portfolio;
