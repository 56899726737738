import React from "react";
import { View } from "react-native";
import { Card, Divider, Text, TouchableRipple } from "react-native-paper";

import tw from "../../../lib/tailwind";
import { HorseProps } from "../../screens/user-stack/Portfolio";
import { InvestmentCardChip } from "./InvestmentCardChip";
import { objectToCleanArray } from "../../utils";
import { HorseOverviewModal } from "../HorseOverviewModal";

type InvestmentCardProps = {
  horses: HorseProps[];
};

export const HorseDetails = ({ horses }: InvestmentCardProps) => {
  horses = objectToCleanArray(horses);

  return (
    <>
      {horses.map((horse, i) => (
        <React.Fragment key={i}>
          <HorseCard horse={horse} />
        </React.Fragment>
      ))}
    </>
  );
};

type HorseCardProps = {
  horse: HorseProps;
};

const HorseCard = ({ horse }: HorseCardProps) => {
  const { name, age, trainer, location, imageUrl, description } = horse;

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  return (
    <Card style={tw`mx-4 my-2 bg-white`}>
      <Card.Content style={tw``}>
        <TouchableRipple onPress={() => setIsModalVisible(true)}>
          <Card.Cover
            source={{
              uri: imageUrl,
            }}
          />
        </TouchableRipple>

        <View style={tw`flex-row flex-wrap items-center`}>
          <Text variant="bodySmall" style={tw`m-2`}>
            Horse Name:
          </Text>
          <Text variant="titleMedium">{name}</Text>
        </View>
        <Divider />
        <View style={tw`flex-row flex-wrap justify-between`}>
          <InvestmentCardChip
            iconName="horse-human"
            modalTitle={`Age: ${age} years old`}
            label={`${age} years old`}
            description={description}
          />
          <InvestmentCardChip
            iconName="map"
            modalTitle={`Location: ${location}`}
            label={location}
            description={description}
          />
          <InvestmentCardChip
            iconName="timer"
            modalTitle={`Trainer: ${trainer}`}
            label={trainer}
            description={description}
          />
          <InvestmentCardChip
            iconName="plus"
            modalTitle="More Info"
            label="More Info"
            description={description}
          />
        </View>
        <HorseOverviewModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          modalTitle="More Info"
          description={description}
        />
      </Card.Content>
    </Card>
  );
};
