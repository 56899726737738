import React from "react";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import { createMaterialBottomTabNavigator } from "@react-navigation/material-bottom-tabs";
import {
  NavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";

import tw from "../../../lib/tailwind";
import colors from "../../../styles/colors";
import UserPageHeader from "../../components/UserPageHeader";
import Discover from "../../screens/user-stack/Discover";
import Portfolio from "../../screens/user-stack/Portfolio";
import ProfilePage from "../../screens/user-stack/ProfilePage";
import Update from "../../screens/user-stack/Update";
import { UserContext } from "../../providers/AuthenticatedUserProvider";
import { AdminProfileProvider } from "../../providers/AdminProfileProvider";
import { ExistingInvestorCheck } from "../../providers/ExistingInvestorCheck";
import Invest from "../../screens/user-stack/Invest";

export const LoggedInProfilePageName = "Account";

export const manageTabTitle = "Manage";
export const portfolioTabTitle = "Portfolio";
export const discoverTabTitle = "Discover";
export const investTabTitle = "Invest";

export const NavLinking = {
  prefixes: ["*"],
};

export default function UserStack() {
  const { user } = UserContext();
  const BottomTab = createMaterialBottomTabNavigator();
  const navigationRef = useNavigationContainerRef();

  const iconProps = {
    size: 24,
    color: colors.green[800],
  };

  return (
    <NavigationContainer linking={NavLinking} ref={navigationRef}>
      <AdminProfileProvider navigationRef={navigationRef} />
      <ExistingInvestorCheck />
      <UserPageHeader navigationRef={navigationRef} />
      <BottomTab.Navigator
        initialRouteName={user?.isAdmin ? manageTabTitle : portfolioTabTitle}
        backBehavior="history"
        barStyle={tw`bg-white`}
      >
        {user?.isAdmin && (
          <BottomTab.Screen
            name={manageTabTitle}
            component={Update}
            options={{
              tabBarIcon({ focused }) {
                return focused ? (
                  <MaterialCommunityIcons name="cog" {...iconProps} />
                ) : (
                  <MaterialCommunityIcons name="cog-outline" {...iconProps} />
                );
              },
            }}
          />
        )}
        <BottomTab.Screen
          name={portfolioTabTitle}
          component={Portfolio}
          options={{
            tabBarIcon({ focused }) {
              return focused ? (
                <MaterialCommunityIcons name="chart-box" {...iconProps} />
              ) : (
                <MaterialCommunityIcons
                  name="chart-box-outline"
                  {...iconProps}
                />
              );
            },
          }}
        />
        <BottomTab.Screen
          name={discoverTabTitle}
          component={Discover}
          options={{
            tabBarIcon({ focused }) {
              return focused ? (
                <MaterialCommunityIcons name="navigation" {...iconProps} />
              ) : (
                <MaterialCommunityIcons
                  name="navigation-outline"
                  {...iconProps}
                />
              );
            },
          }}
        />
        <BottomTab.Screen
          name={investTabTitle}
          component={Invest}
          options={{
            tabBarIcon({ focused }) {
              return focused ? (
                <MaterialCommunityIcons name="cash-multiple" {...iconProps} />
              ) : (
                <MaterialCommunityIcons name="cash" {...iconProps} />
              );
            },
          }}
        />
        <BottomTab.Screen
          name={LoggedInProfilePageName}
          component={ProfilePage}
          options={{
            tabBarIcon({ focused }) {
              return focused ? (
                <MaterialCommunityIcons name="account" {...iconProps} />
              ) : (
                <MaterialCommunityIcons name="account-outline" {...iconProps} />
              );
            },
          }}
        />
      </BottomTab.Navigator>
    </NavigationContainer>
  );
}
