import React from "react";
import { Button, Dialog, Portal, Text } from "react-native-paper";
import { discoverTabTitle } from "../navigation/stacks/UserStack";

type HorseOverviewModalProps = {
  modalTitle: string;
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  description?: string;
};

export const HorseOverviewModal = ({
  modalTitle,
  isModalVisible,
  setIsModalVisible,
  description = `This is just sample data, but head over to the '${discoverTabTitle}' tab to see real opportunities!`,
}: HorseOverviewModalProps) => {
  const hideModal = () => setIsModalVisible(false);
  return (
    <Portal>
      <Dialog visible={isModalVisible} onDismiss={hideModal}>
        <Dialog.Title>{modalTitle}</Dialog.Title>
        <Dialog.Content>
          <Text>{description}</Text>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={hideModal}>Done</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};
