import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { Text, View } from "react-native";
import { Button, IconButton, TextInput } from "react-native-paper";
import { useDeviceContext } from "twrnc";

import { StackHeaderProps } from "@react-navigation/stack";

import { auth } from "../../../lib/firebase";
import tw from "../../../lib/tailwind";
import { ErrorMessage } from "../../components";
import { VisitorPageWrapper } from "../../components/PageWrappers";

export default function SignupScreen({ navigation }: StackHeaderProps) {
  useDeviceContext(tw);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [signupError, setSignupError] = useState("");
  const [signingUp, setSigningUp] = useState(false);

  const onHandleSignup = async () => {
    try {
      if (email === "" || password === "") {
        return setSignupError("Please enter missing fields");
      }
      if (password !== passwordConfirm) {
        return setSignupError("Passwords don't match!");
      }
      setSigningUp(true);
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      setSigningUp(false);
      setSignupError(error.message);
    }
  };

  const PasswordIcon = () => {
    if (hidePassword) {
      return (
        <TextInput.Icon
          onPress={() => setHidePassword(!hidePassword)}
          name="eye"
        />
      );
    }
    return (
      <TextInput.Icon
        name="eye-off"
        onPress={() => setHidePassword(!hidePassword)}
      />
    );
  };

  return (
    <VisitorPageWrapper>
      <>
        <View style={tw`flex-row w-full items-center justify-between p-2`}>
          <View style={tw`flex grow items-start m-0 w-1/3`}>
            <IconButton
              icon="close"
              size={20}
              onPress={() => navigation.navigate("Welcome")}
            />
          </View>
          <Text
            style={{
              ...tw`grow w-1/3 flex text-center justify-center text-3xl font-bold text-gray-900`,
              fontFamily: "Lora",
            }}
          >
            Sign up
          </Text>
          <View style={tw`flex grow items-end w-1/3`}>
            <Button onPress={() => navigation.navigate("Login")} compact>
              Login
            </Button>
          </View>
        </View>
        <View style={tw`w-full flex-1 mt-10 p-8`}>
          <TextInput
            label="Email"
            value={email}
            onChangeText={(text) => {
              setEmail(text);
              setSignupError("");
            }}
            autoFocus={true}
            keyboardType="email-address"
            textContentType="username"
            autoCorrect
            mode="outlined"
            style={tw`mb-4`}
          />
          <TextInput
            label="Password"
            textContentType="newPassword"
            autoCorrect
            secureTextEntry={hidePassword}
            right={PasswordIcon()}
            value={password}
            onChangeText={(text) => {
              setPassword(text);
              setSignupError("");
            }}
            mode="outlined"
            style={tw`mb-4`}
          />
          <TextInput
            label="Confirm Password"
            textContentType="newPassword"
            autoCorrect
            secureTextEntry
            right={<TextInput.Icon name="lock" />}
            autoFocus={false}
            value={passwordConfirm}
            onChangeText={(text) => {
              setPasswordConfirm(text);
              setSignupError("");
            }}
            mode="outlined"
            style={tw`mb-4 ${!password ? "hidden" : ""}`}
          />

          <ErrorMessage error={signupError} />
          <Button
            mode="contained"
            color="black"
            style={tw`w-full`}
            contentStyle={tw`py-2`}
            onPress={onHandleSignup}
            disabled={signingUp}
          >
            {signingUp ? "Creating account.." : "Sign Up"}
          </Button>
        </View>
      </>
    </VisitorPageWrapper>
  );
}
