import { signInWithEmailAndPassword } from "firebase/auth";
import { Formik, FormikValues } from "formik";
import React, { useState } from "react";
import { Platform, Text, View } from "react-native";
import { Button, IconButton, TextInput } from "react-native-paper";
import { useDeviceContext } from "twrnc";
import * as Yup from "yup";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { StackHeaderProps } from "@react-navigation/stack";

import { auth } from "../../../lib/firebase";
import tw from "../../../lib/tailwind";
import { ErrorMessage } from "../../components";
import { VisitorPageWrapper } from "../../components/PageWrappers";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .email("Enter a valid email")
    .required("Please enter a registered email"),
  password: Yup.string()
    .label("Password")
    .required()
    .min(6, "Password must have at least 6 characters "),
});

export default function LoginScreen({ navigation }: StackHeaderProps) {
  useDeviceContext(tw);
  // TODO some duplication between Login and LocalSession provider
  const [loggingIn, setLoggingIn] = useState(false);

  const onLoginHandler = async (values: FormikValues) => {
    const { email, password } = values;
    setLoggingIn(true);

    if (Platform.OS === "ios") {
      setStoredValues(email, password);
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      // TODO add error message handling
      setLoggingIn(false);
    }
  };

  const setStoredValues = async (email: string, password: string) => {
    await AsyncStorage.setItem("login_email_key", email);
    await AsyncStorage.setItem("login_password_key", password);
  };

  return (
    <VisitorPageWrapper>
      <>
        <View style={tw`flex-row w-full items-center justify-between p-2`}>
          <View style={tw`flex grow items-start m-0 w-1/3`}>
            <IconButton
              icon="close"
              size={20}
              onPress={() => navigation.navigate("Welcome")}
            />
          </View>
          <Text
            style={{
              ...tw`grow w-1/3 flex text-center justify-center text-3xl font-bold text-gray-900`,
              fontFamily: "Lora",
            }}
          >
            Log In
          </Text>

          <View style={tw`flex grow items-end w-1/3`}>
            <Button onPress={() => navigation.navigate("Signup")} compact>
              Sign Up
            </Button>
          </View>
        </View>
        <View style={tw`w-full flex-1 mt-10 p-8`}>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={onLoginHandler}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              values,
              errors,
              touched,
              handleSubmit,
              handleBlur,
            }) => (
              <>
                <TextInput
                  numberOfLines={1}
                  value={values.email}
                  placeholder="Enter email"
                  onChangeText={handleChange("email")}
                  autoCapitalize="none"
                  autoCompleteType="email"
                  keyboardType="email-address"
                  onBlur={handleBlur("email")}
                  label="Email"
                  mode="outlined"
                  style={tw`mb-4`}
                />
                <ErrorMessage error={touched.email && errors.email} />
                <TextInput
                  numberOfLines={1}
                  value={values.password}
                  placeholder="Enter password"
                  onChangeText={handleChange("password")}
                  autoCapitalize="none"
                  onBlur={handleBlur("password")}
                  secureTextEntry
                  label="Password"
                  mode="outlined"
                  style={tw`mb-4`}
                />
                <ErrorMessage error={touched.password && errors.password} />
                {/* TODO add error message handling */}
                <Button
                  mode="contained"
                  style={tw`w-full`}
                  contentStyle={tw`py-2`}
                  onPress={handleSubmit}
                  disabled={loggingIn}
                >
                  {loggingIn ? "Logging In..." : "Login"}
                </Button>
              </>
            )}
          </Formik>
        </View>
      </>
    </VisitorPageWrapper>
  );
}
