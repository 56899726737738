export const sampleFundInfo = {
  id: 99,
  name: "Sample Syndicate",
  isSampleData: true,
  investedAmount: 1,
  investedAsGroupName: "Example Investment Group, LLC",
  totalRaised: 100,
  horses: [
    {
      name: "Ride the Lightning",
      imageUrl:
        "https://res.cloudinary.com/the-bit/image/upload/v1680141177/Ghostzapper_-_America_s_Friend_ndz7fj.jpg",
      age: 2,
      trainer: "Horace Trainerson",
      location: "Keeneland, KY",
    },
  ],
};

function formatFunds(funds: any) {
  let fundsArray = objectToCleanArray(funds);

  if (fundsArray.length === 0) {
    return [sampleFundInfo];
  }
  return fundsArray;
}

function objectToCleanArray(object: any) {
  let result = ensureObjectIsArray(object);
  result = cleanArray(result);
  return result;
}

function ensureObjectIsArray(object: any) {
  if (!object) {
    return [];
  }
  if (!Array.isArray(object)) {
    return [object];
  }
  return object;
}

function cleanArray(array: Array<any>) {
  return array.filter((n) => n);
}

export { formatFunds, objectToCleanArray };
