export default {
  green: {
    900: "#014D40",
    800: "#0C6B58",
    700: "#147D64",
    600: "#199473",
    500: "#27AB83",
    400: "#3EBD93",
    300: "#65D6AD",
    200: "#8EEDC7",
    100: "#C6F7E2",
    50: "#EFFCF6",
  },
  gray: {
    900: "#102A43",
    800: "#243B53",
    700: "#334E68",
    600: "#486581",
    500: "#627D98",
    400: "#829AB1",
    300: "#9FB3C8",
    200: "#BCCCDC",
    100: "#D9E2EC",
    50: "#F0F4F8",
  },
  blue: {
    900: "#003E6B",
    800: "#0A558C",
    700: "#0F609B",
    600: "#186FAF",
    500: "#2680C2",
    400: "#4098D7",
    300: "#62B0E8",
    200: "#84C5F4",
    100: "#B6E0FE",
    50: "#DCEEFB",
  },
  purple: {
    900: "#240754",
    800: "#34126F",
    700: "#421987",
    600: "#51279B",
    500: "#653CAD",
    400: "#724BB7",
    300: "#8662C7",
    200: "#A081D9",
    100: "#CFBCF2",
    50: "#EAE2F8",
  },
  red: {
    900: "#610404",
    800: "#780A0A",
    700: "#911111",
    600: "#A61B1B",
    500: "#BA2525",
    400: "#D64545",
    300: "#E66A6A",
    200: "#F29B9B",
    100: "#FACDCD",
    50: "#FFEEEE",
  },
  yellow: {
    900: "#513C06",
    800: "#7C5E10",
    700: "#A27C1A",
    600: "#C99A2E",
    500: "#E9B949",
    400: "#F7D070",
    300: "#F9DA8B",
    200: "#F8E3A3",
    100: "#FCEFC7",
    50: "#FFFAEB",
  },
  white: "white",
  black: "black",
  transparent: "transparent",
};
