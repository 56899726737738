import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDTWBpkEs4qKcMyqINnKrIajAid2bKjcOo",
  appId: "1:516006161831:web:f945c4b3a06582a8431a82",
  authDomain: "the-bit-investing.firebaseapp.com",
  databaseURL: "https://the-bit-investing-default-rtdb.firebaseio.com",
  measurementId: "G-PMEQFF5DW0",
  messagingSenderId: "516006161831",
  projectId: "the-bit-investing",
  storageBucket: "the-bit-investing.appspot.com",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
