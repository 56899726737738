import { getDatabase, onValue, ref } from "firebase/database";
import React, { createContext, useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import { auth } from "../../lib/firebase";
import { formatFunds } from "../utils";
import { HorseProps } from "../screens/user-stack/Portfolio";
import { ProviderProps } from "./types";

export type FundType = {
  id: number;
  name: string;
  investedAmount: number;
  horses: HorseProps[];
  totalRaised: number;
  isSampleData: boolean;
  investedAsGroupName?: string | null;
};

export type UserType = {
  isAdmin: boolean;
  skipInvestorSetup: boolean;
  adminSetupComplete: boolean;
  adminName?: string | null;
  adminFundId?: number | null;
  uid: string;
  displayName: string;
  email: string;
  phoneNumber: string;
  funds?: FundType[];
};

type UserContext = {
  user: UserType | null;
  setUser: (user: any) => void;
};

const AuthenticatedUserContext = createContext<UserContext>({
  user: null,
  setUser: () => null,
});

export const UserContext = () => useContext(AuthenticatedUserContext);

export const AuthenticatedUserProvider: React.FC<ProviderProps> = ({
  children,
}) => {
  const db = getDatabase();
  const [user, setUser] = useState<UserType | null>(null);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [userFunds, setUserFunds] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const delayedLoadingClose = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((authenticatedUser) => {
      setIsLoading(true);
      setUser(null);
      if (authenticatedUser) {
        foundUserInfo(authenticatedUser);
      }

      delayedLoadingClose();
    });

    return unsubscribeAuth;
  }, []);

  function foundUserInfo(userIn: any) {
    const thisUser = { ...userIn };

    setUser((user) => ({ ...user, ...thisUser }));
    setupUserListener(thisUser.uid);
    setupUserFundsListener(thisUser.uid);
    setupAllFundsListener();
  }

  function setupUserListener(uid: string) {
    const reference = ref(db, "users/" + uid);
    onValue(reference, (snapshot) => {
      const snapshotValues = snapshot.val();

      setUser((user) => ({ ...user, ...snapshotValues }));
    });
  }

  function setupUserFundsListener(uid: string) {
    const reference = ref(db, "users_funds/" + uid);
    onValue(reference, (snapshot) => {
      const snapshotValues = snapshot.val();

      const formattedFunds = formatFunds(snapshotValues);

      setUserFunds(formattedFunds);
    });
  }

  function setupAllFundsListener() {
    const reference = ref(db, "funds");
    onValue(reference, (snapshot) => {
      const snapshotValues = snapshot.val();

      const formattedFunds = formatFunds(snapshotValues);

      setAllFunds(formattedFunds);
    });
  }

  useEffect(() => {
    const combinedFunds: FundType[] = [];
    userFunds.forEach((fund: FundType) => {
      allFunds.forEach((globalFund: FundType) => {
        if (fund.id === globalFund.id) {
          combinedFunds.push({ ...fund, ...globalFund });
        }
      });
    });

    setUser((user) => ({ ...user, funds: formatFunds(combinedFunds) }));
  }, [userFunds, allFunds]);

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    <AuthenticatedUserContext.Provider value={{ user, setUser }}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};
