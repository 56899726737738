import React from "react";
import { View } from "react-native";
import { Title, Button, Card } from "react-native-paper";

import tw from "../../../lib/tailwind";
import { UserPageWrapper } from "../../components/PageWrappers";

export default function Invest() {
  return (
    <UserPageWrapper>
      <View style={tw`h-full w-full`}>
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/appfzw32dYHhaYvUh/pagOf4mNKhxlc3hEJ/form"
          frameBorder="0"
          width="100%"
          height="100%"
          style={{ background: "transparent", border: "1px solid #ccc" }}
        ></iframe>
      </View>
    </UserPageWrapper>
  );
}
