import React, { useEffect, useState } from "react";
import { SafeAreaView, ScrollView, View } from "react-native";
import {
  Title,
  Button,
  Card,
  Portal,
  Modal,
  Subheading,
} from "react-native-paper";
import { getDatabase, ref, update } from "firebase/database";

import tw from "../../../lib/tailwind";
import { UserContext } from "../../providers/AuthenticatedUserProvider";
import { UserPageWrapper } from "../../components/PageWrappers";

export default function Update() {
  const { user } = UserContext();
  const [stepNum, setStepNum] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [investorAnswers, setInvestorAnswers] = React.useState({});

  useEffect(() => {
    saveToDb();
  }, [investorAnswers]);

  const hideModal = () => {
    window.location.hash = "";

    if (stepNum == steps.length - 1) {
      setFormSubmitted(true);
    }
  };
  const showModal = () => {
    resetStepNum();
    setInvestorAnswers({});
    window.location.hash = "modal";
  };

  const steps = [
    {
      title: "When are you looking to invest?",
      choices: ["Just browsing", "Ready to invest"],
    },
    {
      title: "How much are you hoping to invest?",
      choices: [
        "Under $100",
        "Between $100 and $1,000",
        "Between $1,000 and $10,000",
        "Over $10,000",
      ],
    },
    {
      title: "How much experience do you have investing in horses?",
      choices: ["None at all", "Some", "A lot of experience"],
    },
    {
      title: "Do you hope to watch your horse race in person?",
      choices: ["Yes", "No"],
    },
    {
      title: "Thank you! A member of our team will reach out shortly.",
    },
  ];

  let currentStepObj = steps[stepNum];
  useEffect(() => {
    currentStepObj = steps[stepNum];
  }, [stepNum]);

  const handleChange = (stepName: string, message: string) => {
    let updatedValue: any = {};
    updatedValue[stepName] = message;

    setInvestorAnswers((horseUpdate) => ({
      ...horseUpdate,
      ...updatedValue,
    }));

    nextStep();
  };

  const saveToDb = () => {
    const db = getDatabase();
    const reference = ref(
      db,
      "intake_questions/" + user?.email.replace(/[^a-zA-Z ]/g, "")
    );
    update(reference, { ...investorAnswers });
  };

  const previousStep = () => {
    if (stepNum > 0) {
      setStepNum(stepNum - 1);
    }
  };

  const nextStep = () => {
    if (stepNum < steps.length - 1) {
      setStepNum(stepNum + 1);
    }
  };

  const resetStepNum = () => {
    setStepNum(0);
  };

  return (
    <UserPageWrapper>
      <View style={tw`h-full w-full`}>
        <Portal>
          <Modal
            visible={!!window.location.hash}
            onDismiss={hideModal}
            contentContainerStyle={tw`m-4`}
            style={tw`items-center`}
          >
            <Card style={tw`pt-4`}>
              <Card.Content>
                <Subheading style={tw`mb-5`}>{currentStepObj.title}</Subheading>

                {currentStepObj.choices?.map((choice) => {
                  return (
                    <Button
                      key={choice}
                      mode="outlined"
                      style={tw`my-2`}
                      onPress={() => {
                        handleChange(currentStepObj.title + Date.now(), choice);
                      }}
                    >
                      {choice}
                    </Button>
                  );
                })}
              </Card.Content>
              <Card.Actions style={tw`mt-5 justify-between`}>
                {stepNum == steps.length - 1 || stepNum == 0 ? (
                  <Button onPress={hideModal} mode="text">
                    Close
                  </Button>
                ) : (
                  <Button onPress={previousStep} mode="text">
                    Back
                  </Button>
                )}
              </Card.Actions>
            </Card>
          </Modal>
        </Portal>
        <Card style={tw`max-w-sm w-full mx-auto mt-6`}>
          <Card.Cover
            source={{
              uri: "https://res.cloudinary.com/the-bit/image/upload/v1665489042/racehorse_j7u0b7.png",
            }}
          />
          <Card.Content style={tw`mt-5`}>
            <Title style={tw`text-center`}>Lets find a horse you'll love</Title>
          </Card.Content>

          <Card.Actions>
            <Button
              mode="contained"
              onPress={showModal}
              style={tw`mt-2 mb-4 w-full`}
            >
              {formSubmitted ? "Thank you!" : "Get Started"}
            </Button>
          </Card.Actions>
        </Card>
      </View>
    </UserPageWrapper>
  );
}
