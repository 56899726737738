import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import {
  Avatar,
  Button,
  Dialog,
  Divider,
  Paragraph,
  Portal,
  Subheading,
  Text,
  Title,
} from "react-native-paper";

import tw from "../../lib/tailwind";
import { getDatabase, onValue, ref } from "firebase/database";

type FundUpdatesModalProps = {
  isUpdatesModalVisible: boolean;
  hideUpdatesModal: () => void;
  allUpdates?: any;
};

export const FundUpdatesModal = ({
  isUpdatesModalVisible,
  hideUpdatesModal,
  allUpdates,
}: FundUpdatesModalProps) => {
  const Updates = () => (
    <View style={tw`grow shrink`}>
      <ScrollView style={tw`h-full`}>
        {Object.entries(allUpdates)
          .slice()
          .reverse()
          .map(([key, entry]) => {
            return (
              <View key={key}>
                <Title style={tw`font-bold`}>
                  {new Date(parseInt(key)).toLocaleDateString()}
                </Title>
                <View style={tw`ml-4`}>
                  {Object.entries(entry as object).map(
                    ([messageSubject, messageBody]) => {
                      if (messageSubject === "General") return;
                      if (messageSubject === "author") return;
                      return (
                        <Message
                          subject={messageSubject}
                          body={messageBody}
                          key={messageSubject}
                        />
                      );
                    }
                  )}

                  <Message subject={"General"} body={entry["General"]} />
                </View>
                <Divider />
              </View>
            );
          })}
      </ScrollView>
    </View>
  );

  return (
    <Portal>
      <Dialog
        style={tw`w-11/12 h-5/6 max-w-md max-h-full self-center items-center relative rounded-none`}
        visible={isUpdatesModalVisible}
        onDismiss={hideUpdatesModal}
      >
        <Dialog.Content style={tw`grow w-full h-full flex`}>
          <CertWatermark />
          <CertHeader />
          {!!Object.keys(allUpdates || {}).length ? (
            <Updates />
          ) : (
            <EmptyState />
          )}
          <CertSignature />
        </Dialog.Content>

        <Dialog.Actions style={tw`absolute top-full p-0 m-2`}>
          <Button
            mode="outlined"
            buttonColor="white"
            textColor="black"
            onPress={hideUpdatesModal}
            style={tw`px-4`}
          >
            Done
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

const EmptyState = () => (
  <View style={tw`grow shrink items-center justify-center`}>
    <Title style={tw`font-bold`}>No updates yet!</Title>
    <Subheading style={tw`font-bold`}>Please check back later.</Subheading>
  </View>
);

const Message = ({ subject, body }: any) => (
  <View key={subject}>
    <Subheading style={tw`font-bold`}>{subject}</Subheading>
    <Paragraph>{body}</Paragraph>
  </View>
);

const CertHeader = () => (
  <View style={tw`items-center`}>
    <Text
      variant="displaySmall"
      style={{ ...tw`text-center m-2`, fontFamily: "Lora" }}
    >
      Updates
    </Text>
  </View>
);

export const CertSignature = () => (
  <View style={tw`flex-row items-center justify-center m-4`}>
    <View style={tw`p-1 rounded-full bg-green-800`}>
      <Avatar.Image
        source={require("../../assets/icons/icon-light.png")}
        size={32}
        style={tw`bg-transparent`}
      />
    </View>

    <Text variant="labelLarge" style={{ ...tw`m-0 ml-2`, fontFamily: "Lora" }}>
      The Bit Investing
    </Text>
  </View>
);

export const CertWatermark = () => (
  <>
    <View
      style={tw`flex items-center justify-center absolute w-full h-full top-0 left-0`}
    >
      <Avatar.Image
        source={require("../../assets/icons/icon.png")}
        size={256}
        style={tw`bg-transparent opacity-3`}
      />
    </View>
  </>
);
