import React from "react";

import UserStack from "../navigation/stacks/UserStack";
import Visitor from "../navigation/stacks/VisitorStack";
import { UserContext } from "./AuthenticatedUserProvider";

export default function RootNavigationProvider() {
  const { user } = UserContext();

  if (user) {
    return <UserStack />;
  }

  return <Visitor />;
}
