import React from "react";
import { Chip } from "react-native-paper";

import tw from "../../../lib/tailwind";
import { HorseOverviewModal } from "../HorseOverviewModal";

type InvestmentCardChipProps = {
  iconName: string;
  modalTitle: string;
  label: string | number;
  description?: string;
};

export const InvestmentCardChip = ({
  iconName,
  modalTitle,
  label,
  description,
}: InvestmentCardChipProps) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  return (
    <>
      <Chip
        icon={iconName}
        mode="flat"
        style={tw`m-1 mr-2 ml-0 bg-white p-0 self-start`}
        textStyle={tw`text-xs my-0 mx-1 mr-2 text-green-900 font-semibold`}
        onPress={() => setIsModalVisible(true)}
        compact
        // elevation={1}
      >
        {label}
      </Chip>
      <HorseOverviewModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        modalTitle={modalTitle}
        description={description}
      />
    </>
  );
};
