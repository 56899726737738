import React from "react";
import { AppRegistry } from "react-native";

import { AuthenticatedUserProvider } from "./src/providers/AuthenticatedUserProvider";
import { LocalSessionProvider } from "./src/providers/LocalSessionProvider";
import RootNavigationProvider from "./src/providers/RootNavigationProvider";
import { BitThemeProvider } from "./src/providers/ThemeProvider";
import { Helmet } from "react-helmet";

export default function App() {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-11460628649"
        ></script>
        <script>
          {` window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-11460628649');`}
        </script>
      </Helmet>
      <BitThemeProvider>
        <LocalSessionProvider>
          <AuthenticatedUserProvider>
            <RootNavigationProvider />
          </AuthenticatedUserProvider>
        </LocalSessionProvider>
      </BitThemeProvider>
    </>
  );
}

AppRegistry.registerComponent("the-bit", () => App);
