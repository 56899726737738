import { signInWithEmailAndPassword } from "firebase/auth";
import { FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";

import AsyncStorage from "@react-native-async-storage/async-storage";

import { auth } from "../../lib/firebase";
import { ProviderProps } from "./types";

export const LocalSessionProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const delayedLoadingClose = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };
  // TODO some duplication between Login and LocalSession provider
  const initializePage = async () => {
    const { email, password } = await getStoredValues();

    if (email && password) {
      onLoginHandler({ email, password });
    } else {
      delayedLoadingClose();
    }
  };

  const getStoredValues = async () => {
    const email = await AsyncStorage.getItem("login_email_key");
    const password = await AsyncStorage.getItem("login_password_key");

    return { email, password };
  };

  const onLoginHandler = async (values: FormikValues) => {
    const { email, password } = values;

    setStoredValues(email, password);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      delayedLoadingClose();
    } catch (error: any) {
      delayedLoadingClose();
    }
  };

  const setStoredValues = async (email: string, password: string) => {
    await AsyncStorage.setItem("login_email_key", email);
    await AsyncStorage.setItem("login_password_key", password);
  };

  useEffect(() => {
    initializePage();
  }, []);

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" />
      </View>
    );
  }
  return <>{children}</>;
};
