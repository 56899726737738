import { reload, signOut, updateEmail, updateProfile } from "firebase/auth";
import { Formik } from "formik";
import React, { useState } from "react";
import { Linking, ScrollView, View } from "react-native";
import {
  ActivityIndicator,
  Avatar,
  Button,
  Divider,
  Text,
  TextInput,
  Title,
} from "react-native-paper";

import { FirebaseError } from "@firebase/util";

import { auth } from "../../../lib/firebase";
import tw from "../../../lib/tailwind";
import colors from "../../../styles/colors";
import { ErrorMessage } from "../../components";
import { EmailUsButton } from "../../components/EmailUsButton";
import { UserContext } from "../../providers/AuthenticatedUserProvider";

export default function ProfilePage() {
  const firebaseUser = auth.currentUser;
  const { user } = UserContext();

  const [showNameForm, setShowNameForm] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [willDelete, setWillDelete] = React.useState(false);

  function deleteAccount() {
    setWillDelete(true);
    Linking.openURL(
      `mailto:support@thebitinvesting.com?subject=DeleteAccount${user?.uid}`
    );
  }

  if (!firebaseUser) return <ActivityIndicator />;

  function updateDisplayName(values: any) {
    if (!firebaseUser) return;
    updateProfile(firebaseUser, values)
      .then(() => {
        setShowNameForm(false);
        firebaseUser && reload(firebaseUser);
      })
      .catch(() => {
        console.error("error!");
      });
  }

  function updateUserEmail(values: any) {
    if (!firebaseUser) return;
    updateEmail(firebaseUser, values.email)
      .then(() => {
        setShowEmailForm(false);
        firebaseUser && reload(firebaseUser);
      })
      .catch((error: FirebaseError) => {
        if (error.code.includes("invalid-email")) {
          setEmailErrorMessage("Invalid email");
        } else {
          setEmailErrorMessage("Please log back in to change your email.");
        }
        console.error(error);
      });
  }

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ScrollView style={tw`bg-white p-4 h-full`}>
      <View style={tw`flex justify-center items-center w-full items-center`}>
        <Divider style={tw`w-full absolute`} />
        <Avatar.Icon
          size={128}
          icon="account-circle-outline"
          color={colors.gray[500]}
          style={tw`m-0 bg-transparent h-auto w-auto`}
        />
        <Title style={tw`text-gray-900`}>
          {firebaseUser.displayName || "Welcome!"}
        </Title>
      </View>

      <View style={tw`p-4`}>
        <Formik
          initialValues={{
            displayName: firebaseUser.displayName || "",
          }}
          onSubmit={updateDisplayName}
        >
          {({ handleChange, values, handleSubmit, handleBlur }) => (
            <View
              style={tw`flex-row flex-wrap items-center justify-between my-2`}
            >
              {!showNameForm && (
                <>
                  <Title style={tw`w-full`}>Display Name:</Title>
                  <Text style={tw`my-1`}>
                    {firebaseUser?.displayName || "New User"}
                  </Text>
                  <EditButton
                    onPress={() => {
                      setShowNameForm(!showNameForm);
                    }}
                  />
                </>
              )}
              {showNameForm && (
                <>
                  <TextInput
                    style={tw`w-full my-1`}
                    value={values.displayName}
                    onChangeText={handleChange("displayName")}
                    onBlur={handleBlur("displayName")}
                    mode="outlined"
                    label="Name"
                  />
                  <Button
                    mode="contained"
                    color={colors.green[800]}
                    onPress={() => handleSubmit()}
                    compact
                    style={tw`ml-auto`}
                  >
                    Save
                  </Button>

                  <EditButton
                    onPress={() => {
                      setShowNameForm(!showNameForm);
                    }}
                    title="Cancel"
                  />
                </>
              )}
            </View>
          )}
        </Formik>

        <Divider style={tw`w-full`} />

        <Formik
          initialValues={{
            email: firebaseUser?.email || "",
          }}
          onSubmit={updateUserEmail}
        >
          {({ handleChange, values, handleSubmit, handleBlur }) => (
            <View
              style={tw`flex-row flex-wrap items-center justify-between my-2`}
            >
              {!showEmailForm && (
                <>
                  <Title style={tw`w-full`}>Email:</Title>
                  <Text style={tw`my-1`}>{firebaseUser?.email}</Text>
                  <EditButton
                    onPress={() => {
                      setShowEmailForm(!showEmailForm);
                    }}
                  />
                </>
              )}
              {showEmailForm && (
                <>
                  <TextInput
                    style={tw`w-full my-1`}
                    value={values.email}
                    onChangeText={handleChange("email")}
                    onBlur={handleBlur("email")}
                    mode="outlined"
                    label="Email"
                  />
                  <ErrorMessage error={emailErrorMessage} />
                  <Button
                    mode="contained"
                    color={colors.green[800]}
                    onPress={() => handleSubmit()}
                    compact
                    style={tw`ml-auto`}
                  >
                    Save
                  </Button>

                  <EditButton
                    onPress={() => {
                      setShowEmailForm(!showEmailForm);
                    }}
                    title="Cancel"
                  />
                </>
              )}
            </View>
          )}
        </Formik>

        <Divider style={tw`w-full`} />

        <EmailUsButton />

        <Button
          onPress={handleSignOut}
          mode="outlined"
          style={tw`w-full`}
          contentStyle={tw`py-2`}
        >
          Logout
        </Button>

        <Divider style={tw`w-full my-2`} />

        {willDelete && (
          <Text style={tw`text-red-500 mb-1`}>
            Sorry to see you go! Your account will be deleted shortly after
            receiving your email.
          </Text>
        )}
        <Button
          onPress={deleteAccount}
          mode="outlined"
          textColor={colors.red[300]}
          style={tw`w-full`}
          contentStyle={tw`py-2`}
        >
          Delete Account
        </Button>
      </View>
    </ScrollView>
  );
}

type EditButtonProps = {
  onPress: () => void;
  title?: string;
};

const EditButton = ({ onPress, title }: EditButtonProps) => {
  return (
    <Button onPress={onPress} compact>
      {title || "Edit"}
    </Button>
  );
};
